<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-form ref="primeiroAcessoForm" @submit.prevent="confirmar">
          <v-card rounded width="1000">
            <v-card-title class="justify-center titulo"
            >Confirmação de Cadastro
            </v-card-title
            >
            <v-card-subtitle class="text-center">
              Para validar o seu cadastro insira o seu CPF
            </v-card-subtitle>
            <div class="text-end text-caption mr-9">
              * Campos obrigatórios
            </div>
            <v-card-text>
              <v-divider class="mb-5"/>
              <v-text-field
                  outlined
                  dense
                  v-model="cpf"
                  maxlength="11"
                  :counter="11"
                  :rules="[campoObrigatorioRule, tamanhoCPFRule]"
                  label="CPF *"
                  v-mask="'###########'"
                  validate-on-blur
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                  :loading="loading"
                  class="mt-4 text-center"
                  color="primary"
                  type="submit"
              >Confirmar
              </v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import usuarioService from "@/services/usuario.service";
import rules from "@/commons/rules";
import {mapActions} from "vuex";

export default {
  name: "PrimeiroAcesso",

  data() {
    return {
      token: this.$route.query.token,
      cpf: "",
      loading: false
    };
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    confirmar() {
      if (
          this.$refs.primeiroAcessoForm.validate() &&
          this.token
      ) {
        this.loading = true;

        let dados = {
          token: this.token,
          cpf: this.cpf
        }

        usuarioService.confirmarPrimeiroAcesso(dados).then(() => {
          this.exibirAviso({
            mensagem: "Usuário cadastrado com sucesso. Você já pode efetuar o seu login",
            tipo: "success"
          });

          this.$router.push("/");
        }).catch(error => {
          let errorData = error.response.data;

          if(errorData.codigoErro === "JW02") {
            error.response.data.msg = "Prazo para confirmar criação de conta expirado, favor criar novamente"
          }

          this.exibirAviso({
            mensagem: error.response.data.msg,
            tipo: "error"
          });
        });
      } else {
        this.$router.push("/naoAutorizado")
      }
      this.loading = false;
    }
  }
};
</script>
